@import '~function.scss';

.alarm-popup {
  .connect-btn {
    margin: vw(10) auto 0 !important;
  }

  .custom-button {
    margin: vw(20) 0 0 0;
  }

  .alarm-message,
  .alarm-message-2 {
    color: var(--brand-dark-gray-color);
    font-size: 3.733vw;
    font-weight: 600;
    line-height: 4.8vw;

    &.center {
      text-align: center;
    }
  }

  .alarm-message-2 {
    margin: 6.933vw 0 0 0;

    &.ch {
      display: flex;
      align-items: center;
      width: 76vw;
      margin: 8vw auto 0;

      .custom-checkbox {
        position: relative;
      }
    }
  }

  .custom-link,
  .connect-btn {
    width: vw(285);
    margin: vw(30) auto 0;
  }

  .alarm-btn {
    position: relative;
    width: 100%;
    height: 11.733vw;
    margin: 6.933vw 0 0 0;
    border-radius: 3.467vw;
    color: var(--brand-white-color);
    background-color: var(--brand-violet-color);
    font-size: 3.2vw;
    font-weight: 500;
    line-height: 11.733vw;

    .icon {
      position: absolute;
      left: 0;
    }
  }

  .plans-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6.933vw 0 0 0;

    .plan-item {
      flex-shrink: 0;
      cursor: pointer;

      .plan {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 76vw;
        height: 42.667vw;
        border-radius: 5.067vw;
        padding: 5.6vw 5.867vw 4.267vw 5.067vw;

        .header {
          display: flex;
          align-items: baseline;
          justify-content: space-between;

          .plan-name {
            margin: 0;
            font-size: 4.267vw;
            font-weight: 600;
            line-height: 6.133vw;
            text-transform: capitalize;

            .plan-icon {
              margin: 0 0 0 -2.133vw;
              padding: 1.733vw 4.8vw;
            }
          }

          .plan-price {
            margin: 0;
            font-size: 4.8vw;
            font-weight: 600;
            text-transform: uppercase;
            text-align: right;

            .plan-currency {
              font-weight: 400;
            }

            .plan-time {
              display: block;
              font-size: 3.2vw;
              font-weight: 400;
              text-transform: lowercase;
              line-height: 4.267vw;
            }
          }
        }

        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .plan-capabilities {
            margin: 0;

            span {
              font-weight: 600;
            }
          }

          .plan-info {
            margin: 0;

            .subscribe-btn {
              padding: 2.133vw;
              border-radius: 3.467vw;
              background-color: var(--brand-white-color);
              font-weight: 600;
              line-height: 4.267vw;
            }
          }
        }
      }

      &.nebula {
        .plan {
          background: var(--brand-light-gray-color);

          .header {
            .plan-name {
              .plan-icon {
                background: url('../../../images/icons/icon-plan-nebula.svg') no-repeat center;
              }
            }

            .plan-price {
              .plan-time {
                font-weight: 600;
              }
            }
          }
        }
      }

      &.protostar,
      &.red-giant,
      &.supernova {
        .plan {
          color: var(--brand-white-color);
          background: transparent linear-gradient(46deg, #9f51f7 0%, #00b2ed 100%);

          .footer {
            .plan-info {
              .plan-time-left {
                color: var(--brand-white-color);
              }

              .select-btn {
                border-radius: 3.467vw;
                padding: 2.133vw;
                background-color: var(--brand-white-color);
                font-weight: 600;
              }

              .unsubscribe-btn {
                color: transparentize(#fefefe, 0.5);
              }
            }
          }
        }
      }

      &.protostar {
        .plan {
          .header {
            .plan-name {
              .plan-icon {
                background: url('../../../images/icons/icon-plan-protostar.svg') no-repeat center;
              }
            }
          }
        }
      }

      &.red-giant {
        .plan {
          .header {
            .plan-name {
              .plan-icon {
                background: url('../../../images/icons/icon-plan-redgiant.svg') no-repeat center;
              }
            }
          }
        }
      }

      &.supernova {
        .plan {
          .header {
            .plan-name {
              .plan-icon {
                background: url('../../../images/icons/icon-plan-supernova.svg') no-repeat center;
              }
            }
          }
        }
      }
    }
  }

  .chat-link {
    margin: 6.933vw auto 0;
  }
}

@media screen and (min-width: 576px) {
  .alarm-popup {
    .alarm-message,
    .alarm-message-2 {
      font-size: 21.5021px;
      line-height: 27.648px;
    }

    .alarm-message-2 {
      margin: 39.9341px 0 0 0;
    }

    .custom-link,
    .connect-btn {
      width: px(285);
      margin: px(30) auto 0;
    }

    .alarm-btn {
      width: 100%;
      height: 67.5781px;
      margin: 39.9341px 0 0 0;
      border-radius: 19.9699px;
      font-size: 18.432px;
      line-height: 67.5781px;
    }

    .plans-list {
      margin: 39.9341px 0 0 0;

      .plan-item {
        .plan {
          width: 437.75px;
          height: 245.75px;
          border-radius: 29.1859px;
          padding: 32.256px 33.7939px 24.5779px 29.1859px;

          .header {
            .plan-name {
              font-size: 24.5779px;
              line-height: 35.3261px;

              .plan-icon {
                margin: 0 0 0 -12.2861px;
                padding: 27.648px 27.648px;
              }
            }

            .plan-price {
              font-size: 27.648px;

              .plan-time {
                font-size: 18.432px;
                line-height: 24.5779px;
              }
            }
          }

          .footer {
            .plan-info {
              .subscribe-btn {
                padding: 12.2861px;
                border-radius: 19.9699px;
                line-height: 24.5779px;
              }
            }
          }

          &.protostar,
          &.red-giant,
          &.supernova {
            .footer {
              .plan-info {
                .select-btn {
                  border-radius: 19.9699px;
                  padding: 12.2861px;
                }
              }
            }
          }

          &.supernova {
            .header {
              .plan-price {
                .plan-time {
                  font-size: 16.8941px;
                }
              }
            }
          }
        }
      }
    }

    .chat-link {
      margin: 39.9341px auto 0;
    }

    .connect-btn {
      margin: 46.08px auto 0;
    }
  }
}
