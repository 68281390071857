@import 'function.scss';

.pay-popup {
  .total-price {
    margin: vw(42) 0;
    color: var(--brand-dark-gray-color);
    text-align: center;

    .title {
      display: block;
      font-size: vw(14);
      line-height: vw(21);
    }

    .currency {
      font-size: vw(32);
      line-height: vw(32);
    }

    .price {
      color: var(--brand-black-color);
      font-size: vw(32);
      font-weight: 600;
      line-height: vw(32);
    }
  }

  &.scroll {
    height: 100%;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 576px) {
  .pay-popup {
    .total-price {
      margin: px(42) 0;

      .title {
        font-size: px(14);
        line-height: px(21);
      }

      .currency {
        font-size: px(32);
        line-height: px(32);
      }

      .price {
        font-size: px(32);
        line-height: px(32);
      }
    }
  }
}
