@import '../node_modules/normalize.css/normalize.css';
@import './variables.scss';
@import '~function.scss';

* {
  box-sizing: border-box;
  outline: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    -webkit-appearance: none;
    display: none;
  }
}

body {
  color: var(--brand-black-color);
  background-color: var(--brand-white-color);
  font-family: 'Poppins', sans-serif;
  font-size: vw(12);
  line-height: vw(18);

  @media screen and (min-width: 576px) {
    background-color: var(--brand-light-gray-color);
    font-size: px(12);
    line-height: px(18);
  }
}

#popstore {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);

  @media screen and (min-width: 576px) {
    width: 576px;
    margin: 0 auto;

    &:before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &:before,
    &::after {
      content: '';
      position: fixed;
      top: 0;
      z-index: 30;
      display: block;
      width: calc(50vw - 288px);
      min-height: calc(var(--vh, 1vh) * 100);
      background-color: var(--brand-light-gray-color);
    }
  }
}

button {
  display: block;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: var(--brand-black-color);
  cursor: pointer;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
  overflow: initial;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

a {
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input,
textarea {
  width: 100%;
  border-radius: vw(13);
  border: 0;
  padding: 0;
  background-color: var(--brand-light-gray-color);
  font-size: vw(12);
  font-weight: 400;
  line-height: vw(16);

  &::placeholder {
    color: var(--brand-dark-gray-color);
  }

  &:focus {
    outline-color: var(--brand-violet-color);
  }

  @media screen and (min-width: 576px) {
    border-radius: px(13);
    font-size: px(12);
    line-height: px(17);
  }
}

textarea {
  resize: none;
}

.no-scroll {
  overflow: hidden;
}

figure {
  margin: 0;
}

.scroll-container {
  .simplebar-content-wrapper {
    outline: none;
  }

  .simplebar-horizontal {
    height: vw(4) !important;
    border-radius: vw(7) !important;

    .simplebar-scrollbar {
      top: 0 !important;
      height: vw(4) !important;

      &.simplebar-visible {
        &:before {
          background-color: var(--brand-blue-color) !important;
          opacity: 1 !important;
        }
      }

      &:before {
        background-color: var(--brand-blue-color) !important;
      }
    }
  }

  .simplebar-vertical {
    width: vw(4) !important;
    right: vw(12) !important;

    .simplebar-scrollbar {
      &::before {
        width: 100% !important;
        background-color: var(--brand-blue-color) !important;
        opacity: 1 !important;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .scroll-container {
    .simplebar-horizontal {
      height: px(4) !important;
      border-radius: px(7) !important;

      .simplebar-scrollbar {
        height: px(4) !important;
      }
    }

    .simplebar-vertical {
      width: px(4) !important;
      right: px(12) !important;
    }
  }
}
